
import { ReactElement, createContext, useContext, useState } from "react";
import { toast } from "react-toastify";

const RootContext = createContext<RootType>(null!)

interface RootType {
    msg: string | undefined
    setMsg: (msg: string | undefined) => void
    notify: (msg: any) => any
}

export function useRootProvider() {
	return useContext(RootContext)
}


export function RootProvider({ children }: { children: ReactElement }) {
	const [msg, setMsg] = useState<string | undefined>()

	function notify(msg: any) {
		toast(msg.toString(), {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			type:'success'
		});
	}

    const value = {
        msg,
        setMsg,
        notify,
    }

	return (
		<RootContext.Provider value={value}>
			{children}
		</RootContext.Provider>
	)
}
