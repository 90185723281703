import { BaseResponse } from "../../model/base-response"
import { ApiPath } from "./api-path"

export type FromJsonFunction<T> = (json: any) => T

export class Http<T> {
    private method: string = 'GET'
    private path?: string
    private body?: any
    private query?: object
    private params?: any
    private fromJson?: FromJsonFunction<T>

    static instance<T>(): Http<T> {
        return new Http<T>
    }

    setPath(path: string): Http<T> {
        this.path = path
        return this
    }

    setBody(body: any): Http<T> {
        this.body = JSON.stringify(body)
        return this
    }

    setQuery(query: any): Http<T> {
        this.query = query
        return this
    }

    setParams(params: any): Http<T> {
        this.params = params
        return this
    }

    onFromJson(fromJson: FromJsonFunction<T>): Http<T> {
        this.fromJson = fromJson
        return this
    }

    post(): Promise<BaseResponse<T>> {
        this.method = 'POST'
        return this._makeRequest()
    }

    put(): Promise<BaseResponse<T>> {
        this.method = 'PUT'
        return this._makeRequest()
    }

    get(): Promise<BaseResponse<T>> {
        return this._makeRequest()
    }
    build() {
        var queryString = ''
        if (this.query) {
            queryString = `?${Object.entries(this.query).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`
        }
        if (this.params) {
            for (const key in this.params) {
                const element = this.params[key];
                this.path = this.path?.replaceAll(RegExp(`{${key}}`, 'g'), element)
            }
        }
        console.log(this.path);
    }

    async _makeRequest(): Promise<any> {
        try {

            var queryString = ''
            if (this.query) {
                queryString = `?${Object.entries(this.query).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`
            }
            if (this.params) {
                for (const key in this.params) {
                    const element = this.params[key];
                    this.path = this.path?.replaceAll(RegExp(`{${key}}`, 'g'), element)
                }
            }

            const result = await fetch(ApiPath.baseUrl + this.path + queryString, {
                method: this.method,
                headers: {
                    token: localStorage.getItem('token') ?? '',
                    'content-type': 'application/json'
                },
                body: this.body
            })


            const data = await result.json()
            return BaseResponse.fromJson(data, this.fromJson)
        } catch (error) {
        }

    }
}