import { BrandModel } from "./brand-model"
import { SourceModel } from "./source-model"
import { StoreModel } from "./store-model"

export class ProductModel {
    _id?: string
    categories?: Array<CategoryModel>
    options?: Array<OptionModel>
    items?: any[]
    status?: string
    target?: string
    stores?: Array<StoreModel>
    name?: string
    image?: string
    sku?: string
    price?: number
    unit?: string
    description?: string
    note?: string
    itemType?: string
    source?: SourceModel
    store?: StoreModel
    brand?: BrandModel
    isSelected?: boolean

    static fromJson(json: ProductModel): ProductModel {
        const data = Object.assign(new ProductModel(), json)
        if (json.categories) {
            data.categories = Object.assign(new Array<CategoryModel>(), json.categories)
        }
        if (json.options) {
            data.options = Object.assign(new Array<OptionModel>(), json.options)
        }
        if (json.items) {
            data.items = json.items
        }
        if (json.stores) {
            data.stores = Object.assign(new Array<StoreModel>(), json.stores)
        }
        if (json.source) {
            data.source = Object.assign(new SourceModel(), json.source)
        }
        if (json.store) {
            data.store = Object.assign(new StoreModel(), json.store)
        }
        if (json.brand) {
            data.brand = Object.assign(new BrandModel(), json.brand)
        }
        return data
    }

    displayCategory(): string {
        return this.categories?.map((it) => it.name).join(', ') ?? ''
    }
}

export class CategoryModel {
    _id?: string
    status?: string
    target?: string
    name?: string
    source?: string
    brand?: string

    static fromJson(json: CategoryModel): CategoryModel {
        const data = Object.assign(new CategoryModel(), json)
        return data
    }
}



export class OptionModel {
    _id?: string
    maxCount?: number
    minCount?: number
    status?: string
    target?: string
    name?: string
    source?: string
    choices?: Array<ChoiceModel>
    created?: string
    updated?: string

    static fromJson(json: OptionModel): OptionModel {
        const data = Object.assign(new OptionModel(), json)
        if (json.choices) {
            data.choices = Object.assign(new Array<ChoiceModel>(), json.choices)
        }
        return data
    }

}

export class ChoiceModel {
    _id?: string
    name?: string
    price?: number
    maxCount?: number
    isDefault?: boolean
    created?: string
    updated?: string

    static fromJson(json: ChoiceModel): ChoiceModel {
        const data = Object.assign(new ChoiceModel(), json)
        return data
    }
}