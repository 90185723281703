
import { ReactElement, ReactNode } from "react";
import { RootProvider, useRootProvider } from "./provider";
import { Snackbar } from "@mui/material";
import { ToastContainer } from "react-toastify";

interface RootArgs {
    children?: ReactNode
}

function RootPage(args: RootArgs) {
    return (
        <RootProvider>
            <View children={args.children} />
        </RootProvider>
    );
}

function View(args: RootArgs) {
    const provider = useRootProvider()
    return (
        <div>
            {args.children}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: 'right' }}
                open={provider.msg != undefined}
                onClose={() => provider.setMsg(undefined)}
                message={provider.msg} />
            <ToastContainer />
        </div>
    )
}

export default RootPage
