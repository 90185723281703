
import { Autocomplete, AutocompleteRenderInputParams, Button, Divider, Pagination, Stack, Table, TableBody, TableHead, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { mapToRow } from "../../common/utils/utils";
import { BrandModel } from "../../model/brand-model";
import { ProductFilterModel } from "../../model/product-filter-model";
import { ProductModel } from "../../model/product-model";
import { SourceModel } from "../../model/source-model";
import { StoreModel } from "../../model/store-model";
import ContentScroll from "../../provider/content_scroll";
import { ProductListProvider, useProductListProvider } from "./provider";
import ModalConfig from "./widget/modal-config";
import ModalUpdate from "./widget/modal-update";
import { SortBy } from "../../model/table-cell-model";

function ProductListPage() {
    return (
        <ProductListProvider>
            <View />
        </ProductListProvider>
    );
}

function View() {
    const provider = useProductListProvider()
    const [search, setSearch] = useSearchParams()
    const [page, setPage] = useState<number>(0)

    useEffect(() => {
        setPage(+(search.get('page') ?? '0'))
    }, [search.get('page') ?? '0'])

    async function _onSelect(_: any, value: BrandModel | null) {
        provider.setFilter(
            ProductFilterModel.fromJson({
                ...provider.filter,
                brand: value ?? undefined,
                store: undefined,
            })
        )
        setSearch((prev) => {
            const data: any = {
                ...Object.fromEntries(prev.entries()),
                brand: value?._id?.toString() ?? '',
                page: 1,
            }
            delete data.store
            return data
        })
    }

    function _onSelectStore(_: any, value: StoreModel | null) {
        provider.setFilter(
            ProductFilterModel.fromJson({
                ...provider.filter,
                store: value ?? undefined,
                source: undefined
            })
        )
        setSearch((prev) => {
            const data: any = {
                ...Object.fromEntries(prev.entries()),
                store: value?._id?.toString() ?? "",
                page: 1
            }
            delete data.source
            return data
        })


    }

    function _onSelectSource(_: any, value: SourceModel | null) {
        setSearch((prev) => {
            const data: any = {
                ...Object.fromEntries(prev.entries()),
                source: value?._id?.toString() ?? '',
                page: 1
            }
            return data
        })
    }

    function _onChangePage(event: ChangeEvent<unknown>, page: number) {
        setSearch((prev) => {
            const data: any = {
                ...Object.fromEntries(prev.entries()),
                page: page
            }
            return data
        })
    }

    function _onSearch() {
        setSearch((prev) => {
            const data: any = {
                ...Object.fromEntries(prev.entries()),
                search: provider.searchRef.current?.value ?? '',
                page: 1,
            }
            return data
        })
    }

    function _onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            _onSearch()
        }
    }

    function _onCheckChange(checked: boolean, product: ProductModel) {
        product.isSelected = checked
        provider.setProducts(
            provider.products.map((it) => it)
        )
    }

    function _onPressed(index: number) {
        const cell = provider.cells.find((it) => it.id == index)


        if (index === 0) {
            const checkAll = !(provider.filter?.checkAll ?? false)
            provider.setFilter({
                ...provider.filter,
                checkAll: checkAll
            })
            provider.setProducts(
                provider.products.map((it) => {
                    it.isSelected = checkAll
                    return it
                })
            )
        } else if (index === 2 || index === 4 || index === 5) {

            if (cell?.sortBy === undefined || cell.sortBy == null || cell.sortBy === SortBy.desc) {
                cell!.sortBy = SortBy.asc
            } else {
                cell!.sortBy = SortBy.desc
            }
            setSearch((prev) => {
                const data: any = {
                    ...Object.fromEntries(prev.entries()),
                    sortBy: `${index}-${cell?.sortBy}`
                }
                return data
            })
        }
    }

    function _onRowPressed(index: number, product: ProductModel) {

        if (index === 2) {
            provider.setProducts(
                provider.products.map((it) => {
                    it.isSelected = false
                    if (it._id === product._id) {
                        it.isSelected = true
                    }
                    return it
                })
            )
            _onUpdateMany()
        }
    }

    function _onUpdateMany() {
        provider.setOpenPrice(true)
    }

    return (

        <ContentScroll>
            <Stack mt={4}>
                <Stack p={3}>
                    <Stack direction={'row'} >
                        <Autocomplete
                            value={provider.filter?.brand || null}
                            getOptionLabel={(option) => option.name ?? ''}
                            options={provider.brands}
                            fullWidth
                            onChange={_onSelect}
                            renderInput={(params: any) => <TextField {...params} label="Brand" />}
                        />
                        <Autocomplete
                            value={provider.filter?.store || null}
                            getOptionLabel={(option) => option.name ?? ''}
                            options={provider.stores}
                            fullWidth
                            sx={{ ml: 3 }}
                            onChange={_onSelectStore}
                            renderInput={(params: any) => <TextField {...params} label="Store" />}
                        />
                        <Autocomplete
                            value={provider.filter?.source || null}
                            getOptionLabel={(option) => option.name ?? ''}
                            options={provider.sources}
                            fullWidth
                            sx={{ ml: 3 }}
                            onChange={_onSelectSource}
                            renderInput={(params: AutocompleteRenderInputParams) => <TextField
                                {...params}
                                label="Source" />}
                        />
                    </Stack>
                    <Stack direction={'row'} mt={3}>
                        <TextField
                            label="Search"
                            fullWidth
                            inputRef={provider.searchRef}
                            onKeyDown={_onKeyDown}
                            defaultValue={search.get('search')}
                            placeholder="Search product" />
                        <Button variant="contained" sx={{ ml: 3 }} fullWidth onClick={_onSearch}>
                            Search
                        </Button>

                    </Stack>
                    <Stack direction={'row'} sx={{ mt: 3, justifyContent: 'space-between' }} >
                        {
                            provider.meta && (
                                <Pagination
                                    page={page}
                                    count={provider.meta.totalPage}
                                    onChange={_onChangePage}
                                    color="primary" />
                            )
                        }
                        {
                            provider.products.find((it) => it.isSelected == true) && (
                                <Button
                                    size="small"
                                    onClick={_onUpdateMany}
                                    variant="text">
                                    Update Many
                                </Button>
                            )
                        }
                        <Button
                            size="small"
                            onClick={() => provider.setOpen(true)}
                            variant="text">
                            Configuration
                        </Button>
                    </Stack>

                </Stack>
                <Divider />
                <Table>
                    <TableHead style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        borderTop: 0,

                    }}>
                        {
                            mapToRow({
                                isHeader: true,
                                cells: provider.cells,
                                onPressed: _onPressed
                            })
                        }
                    </TableHead>
                    <TableBody>
                        {
                            provider.products.map((it, index) => (
                                mapToRow({
                                    cells: provider.cells,
                                    key: it._id,
                                    checkboxIndex: 0,
                                    onCheckChange: (checked) => _onCheckChange(checked, it),
                                    onPressed: (index) => _onRowPressed(index, it),
                                    data: [
                                        it.isSelected,
                                        (page - 1) * 50 + index + 1,
                                        <b>{it.name}</b>,
                                        new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(it.price ?? 0),
                                        it.displayCategory(),
                                        it.status,
                                        it.store?.name,
                                        it.source?.name,
                                        it.brand?.name,
                                    ]
                                })
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    provider.meta && (
                        <Pagination
                            page={page}
                            count={provider.meta.totalPage}
                            sx={{ mt: 3 }}
                            onChange={_onChangePage}
                            color="primary" />
                    )
                }
            </Stack>
            <ModalConfig />
            <ModalUpdate />
        </ContentScroll>
    );
}

export default ProductListPage
