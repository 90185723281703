import { Route, redirect, useNavigate } from "react-router-dom";
import { RoutePath } from "../common/router/route-path";
import { useEffect } from "react";

interface NotFoundPageType {

}

const NotFoundPage = (props: NotFoundPageType) => {
    const navigate = useNavigate()

    useEffect(() => {
        // redirect(RoutePath.products)
        navigate(RoutePath.products, { replace: true })
    }, [])

    return (
        <div></div>
    );
}

export default NotFoundPage