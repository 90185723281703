import { Checkbox, TableCell, TableRow } from "@mui/material";
import { TableCellModel } from "../../model/table-cell-model";
import { ReactNode } from "react";

export class ToRowMapper {
    cells?: Array<TableCellModel>
    isHeader?: boolean = false
    data?: Array<any>
    key?: any
    checkboxIndex?: number
    onCheckChange?: (checked: boolean) => void
    onPressed?: (index: number) => void
}

export function mapToRow(mapper?: ToRowMapper): ReactNode {
    const cells = []
    const sortCells = mapper?.cells ?? []
    for (const cell of sortCells) {
        if (cell.isShow) {
            var content: string | ReactNode | null = <b>{cell.name}</b>
            if (!mapper?.isHeader) {
                content = mapper?.data?.at(cell.id ?? 0)
            }
            if (mapper?.checkboxIndex === cell.id) {
                content = (
                    <Checkbox checked={mapper?.data?.at(cell.id ?? 0) || null} onChange={(_, checked) => {
                        if (mapper?.onCheckChange) {
                            mapper?.onCheckChange(checked)
                        }
                    }} />
                )
            }
            cells.push(
                <TableCell
                    onClick={() => {
                        if (mapper?.onPressed) {
                            mapper.onPressed(cell.id!)
                        }
                    }}
                >{content}</TableCell>
            )
        }
    }

    return (
        <TableRow key={mapper?.key}>
            {cells}
        </TableRow>
    )
}