
import { Button, Checkbox, Container, FormControlLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import { LoginProvider, useLoginProvider } from "./provider";
import React, { ChangeEvent } from "react";

function LoginPage() {
    return (
        <LoginProvider>
            <View />
        </LoginProvider>
    );
}

function View() {
    const provider = useLoginProvider()

    function _onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key == 'Enter') {
            provider.onSubmit()
        }
    }
    function _onChangeChecked(_: ChangeEvent<HTMLInputElement>, checked: boolean): void {
        provider.setShowPass(checked)
    }

    return (
        <Container sx={{
            textAlign: 'center',
            p: 16
        }}>
            <Typography variant="h3">
                <b>Hub CMS</b>
            </Typography>
            <OutlinedInput
                placeholder="User Name"
                fullWidth
                sx={{
                    mt: 4
                }}
                inputRef={provider.userNameRef} />
            <OutlinedInput
                placeholder="Password"
                onKeyDown={_onKeyDown}
                sx={{
                    mt: 4
                }}
                type={provider.showPass ? 'text' : 'password'}
                fullWidth
                inputRef={provider.passwordRef} />
            <Stack
                justifyContent={'start'}>
                <FormControlLabel control={<Checkbox onChange={_onChangeChecked} />} label="Show Password" />
            </Stack>


            <Button
                sx={{
                    mt: 4
                }}
                onClick={provider.onSubmit}
                fullWidth
                variant="contained">
                Login
            </Button>
        </Container>
    );
}

export default LoginPage
