import { Box, Typography, debounce } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const pathMap = new Map<string, number>()

interface ContentScrollType {
	children?: ReactNode
	px?: number
	noScroll?: boolean
}

const ContentScroll = (props: ContentScrollType) => {

	const ref = useRef<any>()
	const { pathname } = useLocation();


	useEffect(() => {

		const position = pathMap.get(pathname) ?? 0
		if (ref?.current) {
			ref.current.scrollTop = position;
		} else {
			window.scrollTo(0, position)
		}
	}, [pathname, ref.current]);

	useEffect(() => {
		const fn = debounce(() => {
			pathMap.set(pathname, ref?.current.scrollTop);
		}, 200);

		if (ref?.current) {
			ref?.current.addEventListener('scroll', fn);
		}

		// return () => ref?.current.removeEventListener('scroll', fn);
	}, [pathname, ref.current]);


	return (

		<Box
			ref={ref}
			overflow={!props.noScroll ? 'scroll' : ''}
		>
			{
				props.children
			}
		</Box>

	);
};

export default ContentScroll;
