export enum SortBy {
    asc,
    desc,
}

export class TableCellModel {
    id?: number
    name?: string
    isShow?: boolean
    sortBy?: SortBy

    static fromJson(json: TableCellModel): TableCellModel {
        const data = Object.assign(new TableCellModel(), json)
        return data
    }
}