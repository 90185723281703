import { Button, Checkbox, Container, Modal, Typography } from "@mui/material";
import { useProductListProvider } from "../provider";
import { TableCellModel } from "../../../model/table-cell-model";
import { useSearchParams } from "react-router-dom";

interface ModalConfigType {

}

const ModalConfig = (props: ModalConfigType) => {
	const provider = useProductListProvider()
	const [search, setSearch] = useSearchParams()

	function _onChangeShow(cell: TableCellModel) {
		cell.isShow = !(cell.isShow ?? false)
		setSearch((prev) => {
			const data: any = {
				...Object.fromEntries(prev.entries()),
				cells: provider.cells.filter((it) => it.isShow === true).map((it)=> it.id).join('-')
			}
			return data
		})
	}

	return (
		<Modal
			open={provider.open}
			onClose={() => provider.setOpen(false)}
			sx={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<Container
				sx={{
					backgroundColor: 'white',
					maxWidth: 400,
					borderRadius: 4,
					p: 2,
				}}>
				{
					provider.cells.map((it) => (
						<Button
							key={it.id}
							onClick={() => _onChangeShow(it)}
							variant="text"
							style={{ display: "flex", width: '100%', alignItems: 'center', flexGrow: 1 }}>
							<Checkbox checked={it.isShow} />
							<Typography sx={{ flexGrow: 1 }}>{it.name}</Typography>
						</Button>
					))
				}
			</Container>
		</Modal>
	);
};

export default ModalConfig;
