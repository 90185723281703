import { BrandModel } from "./brand-model";

export class ProductFilterModel {
    brand?: BrandModel
    store?: BrandModel
    source?: BrandModel
    checkAll?: boolean

    static fromJson(json: ProductFilterModel): ProductFilterModel {
        const data = Object.assign(new ProductFilterModel(), json)
        if (json.brand) {
            data.brand = Object.assign(new BrandModel(), json.brand)
        }
        if (json.store) {
            data.store = Object.assign(new BrandModel(), json.store)
        }
        if (json.source) {
            data.source = Object.assign(new BrandModel(), json.source)
        }
        return data
    }
}