export class UserModel {
    _id?: string
    phone?: string
    username?: string
    token?: string
    
    static fromJson(json: UserModel): UserModel {
        const data = Object.assign(new UserModel(), json)
        return data
    }
}