import { Route, Routes, ScrollRestoration } from 'react-router-dom';
import ProductListPage from './router/product-list';
import { RoutePath } from './common/router/route-path';
import LoginPage from './router/login';
import RootPage from './provider/root';
import { PrivateRoute } from './provider/private-page';
import MainPage from './provider/main-page';
import NotFoundPage from './provider/not-found-page';

function App() {
  return (
    <RootPage>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route element={<MainPage />}>
            <Route path={RoutePath.products} element={< ProductListPage />} />
          </Route>
        </Route>


        <Route path={RoutePath.login} element={<LoginPage />} />

        <Route path={'*'} element={<NotFoundPage />} />
       
      </Routes>
      
    </RootPage>

  );
}

export default App;
