
import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import { Http } from "../../common/http";
import { UserModel } from "../../model/user-model";
import { useRootProvider } from "../../provider/root/provider";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../common/router/route-path";
import { ApiPath } from "../../common/http/api-path";

const LoginContext = createContext<LoginType>(null!)

interface LoginType {
    userNameRef: RefObject<HTMLInputElement | undefined>
    passwordRef: RefObject<HTMLInputElement | undefined>
    showPass: boolean
    setShowPass: (showPass: boolean) => void
    onSubmit: () => any
}

export function useLoginProvider() {
	return useContext(LoginContext)
}


export function LoginProvider({ children }: { children: ReactElement }) {
	const userNameRef = useRef<HTMLInputElement | undefined>()
	const passwordRef = useRef<HTMLInputElement | undefined>()
	const [showPass, setShowPass] = useState<boolean>(false)
	const rootProvider = useRootProvider()
	const navigate = useNavigate()

	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token) {
			navigate(RoutePath.products)
		}
	}, [])

	async function onSubmit() {
		const result = await Http.instance<UserModel>()
			.setPath(ApiPath.login)
			.setBody({
				email: userNameRef.current?.value,
				password: passwordRef.current?.value,
			})
			.onFromJson(UserModel.fromJson)
			.post()
		console.log(result);

		if (!result.isOke()) {
			rootProvider.setMsg(result.error?.message)
			return
		}
		localStorage.setItem('token', result.data?.token ?? '')
		navigate(RoutePath.products, { replace: true })
	}

    const value = {
        userNameRef,
        passwordRef,
        showPass,
        setShowPass,
        onSubmit,
    }
	return (
		<LoginContext.Provider value={value}>
			{children}
		</LoginContext.Provider>
	)
}
