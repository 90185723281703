import { FromJsonFunction } from "../common/http"

export class BaseResponse<T> {
    meta?: MetaModel
    error?: ErrorModel
    data?: T

    static fromJson<T>(json: any, fromJson: FromJsonFunction<T> | undefined): BaseResponse<T> {
        const data = Object.assign(new BaseResponse<T>(), json)
        if (json.error) {
            data.error = Object.assign(new ErrorModel(), json.error)
        }
        if (json.meta) {
            data.meta = Object.assign(new MetaModel(), json.meta)
        }
        if (json.data) {
            data.data = fromJson != null ? fromJson!(json.data) : json.data
        }
        return data
    }
    isOke() {
        return this.error == null || this.error == undefined
    }
}

export class ErrorModel {
    message?: string

    static fromJson(json: ErrorModel): ErrorModel {
        const data = Object.assign(new ErrorModel(), json)
        return data
    }
}

export class MetaModel {
    total?: number
    page?: number
    totalPage?: number

    static fromJson(json: MetaModel): MetaModel {
        const data = Object.assign(new MetaModel(), json)
        return data
    }
}